import React, { useEffect } from "react";
import "./ProjectsPage.css";
import data from "../data/data";
import ProjectItem from "../components/ProjectItem";

const ProjectsPage = () => {
  useEffect(() => {
    document.title = "Personal Projects page";
  }, []);
  return (
    <div className="projects_screen">
      <div className="all_projects">
        <section className="projects_info">
          <p>
            <b>Please Note:</b> Some projects may experience a delay of up to 40
            seconds before loading. This delay is due to the utilization of free
            hosting services, which may have limited resources. Your patience is
            appreciated as the projects load.
          </p>
        </section>
        <h1>Featured Work</h1>
        <section className="projects_section">
          {data.map((element, index) => (
            <ProjectItem
              key={index}
              id={index}
              image={element.image}
              name={element.name}
              link={element.live_link}
              stack={element.teck_stack}
            />
          ))}
        </section>
        <p>
          few icons here{" "}
          <a target="_blank" href="https://icons8.com">
            by Icons8
          </a>
        </p>
      </div>
    </div>
  );
};

export default ProjectsPage;
