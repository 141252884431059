import React, { useEffect } from "react";
import { motion } from "framer-motion";
import "./HomePage.css";
import pic from "../assets/pic.jpg";

const HomePage = () => {
  useEffect(() => {
    document.title = "Welcome to my portfolio";
  }, []);
  return (
    <div className="homepage">
      <div className="inner_section">
        <section className="myimage_section">
          <img src={pic} alt="personal_pic" />

          <motion.div
            initial={{ opacity: 0, x: -100, y: 0, scale: 1 }}
            animate={{
              x: 0,
              y: 0,
              scale: 1,
              rotate: 0,
              opacity: 1,
            }}
            transition={{
              type: "spring",
              damping: 10,
              stiffness: 360,
            }}
          >
            <p>
              Hi, I'm Driss, a software developer passionate about crafting
              immersive digital experiences. With over 2 years of experience in
              web development, I blend creativity with technical expertise to
              bring app ideas to life.
            </p>
            <div className="other_links">
              <h1>Other Links</h1>
              <div>
                <p align="left">
                  {" "}
                  <a
                    href="https://www.github.com/drissbrh"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/socials/github-dark.svg"
                      width="32"
                      height="32"
                    />
                  </a>{" "}
                  <a
                    href="https://www.twitter.com/drissbrDev"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/socials/twitter-dark.svg"
                      width="32"
                      height="32"
                    />
                  </a>
                </p>
              </div>
            </div>
          </motion.div>
        </section>
      </div>
    </div>
  );
};

export default HomePage;
