import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./Screens/HomePage";
import NavBar from "./components/NavBar";
import ContactPage from "./Screens/ContactPage";
import ServicesPage from "./Screens/ServicesPage";
import ProjectsPage from "./Screens/ProjectsPage";
import SideDrawer from "./components/SideDrawer";
import BackDrop from "./components/BackDrop";

function App() {
  const [sideToggle, setSideToggle] = useState(false);
  return (
    <Router>
      <div className="App">
        <NavBar click={() => setSideToggle(true)} />
        <BackDrop show={sideToggle} click={() => setSideToggle(false)} />
        <SideDrawer show={sideToggle} click={() => setSideToggle(false)} />
        {/* All Routes */}
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          {/* <Route path="/contact" exact element={<ContactPage />} /> */}
          {/* <Route path="/services" exact element={<ServicesPage />} /> */}
          <Route path="/projects" exact element={<ProjectsPage />} />
          <Route path="*" exact element={<HomePage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
