import React, { useState } from "react";
import { motion } from "framer-motion";
import "./ProjectItem.css";
import Carroussel from "./Carroussel";
// import image from "../assets/game.PNG";

const ProjectItem = ({ id, name, image, stack, link }) => {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const openGallery = () => {
    setIsGalleryOpen(true);
  };

  const closeGallery = () => {
    setIsGalleryOpen(false);
  };
  return (
    <div className="project_item" key={id}>
      <motion.div
        className="project_image_section"
        initial={{ opacity: 0, x: -100, y: 0, scale: 1 }}
        animate={{
          x: 0,
          y: 0,
          scale: 1,
          rotate: 0,
          opacity: 1,
        }}
        transition={{
          type: "",
          damping: 20,
          stiffness: 360,
        }}
        onClick={openGallery}
      >
        <img src={image[0]} alt={`project${id}`} />
      </motion.div>
      <Carroussel
        platforms={image}
        isOpen={isGalleryOpen}
        onClose={closeGallery}
      />
      <section className="project_info_section">
        <h2>{name}</h2>
        <p>
          <a href={link} target="_blank" rel="noreferrer">
            🚀 Link
          </a>
        </p>
        <div className="used_stack">
          <p>Stack used: </p>
          {stack.map((e) => (
            <img src={e.name} title={e.alt} alt={e.alt} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default ProjectItem;
