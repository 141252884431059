import { Link } from "react-router-dom";
import "./NavBar.css";

const NavBar = ({ click }) => {
  return (
    <>
      <nav className="navbar">
        <div className="nav__logo">{/* <Link to="/"></Link> */}</div>
        <ul className="navbar__links">
          <li>
            <Link to="/">Homepage</Link>
          </li>

          {/* <li className="vertical__">|</li>
          <li>
            <Link to="/services">Services</Link>
          </li> */}

          <li className="vertical__">|</li>
          <li>
            <Link to="/projects">Projects</Link>
          </li>

          {/* <li className="vertical__">|</li>
          <li>
            <Link to="/contact">Contact</Link>
          </li> */}
        </ul>
        <div className="threeLines" onClick={click}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
