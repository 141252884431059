// vgd images
import gameImage0 from "../assets/game.PNG";
import gameImage1 from "../assets/game1.PNG";
import gameImage2 from "../assets/game2.PNG";
// medical system images
import medicalImage0 from "../assets/medical.PNG";
import medicalImage1 from "../assets/medical1.PNG";
import medicalImage2 from "../assets/medical2.PNG";

// sneakers images
import sneakersImg from "../assets/sneakers.PNG";
import sneakersImg1 from "../assets/sneakers1.PNG";
import sneakersImg2 from "../assets/sneakers2.PNG";

//icons
import spring from "../assets/spring-boot.svg";

let postgres =
  "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/postgresql-colored.svg";

let react =
  "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/react-colored.svg";

let redux =
  "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/redux-colored.svg";

let NodeJs =
  "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/nodejs-colored.svg";

let express =
  "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/express-dark.svg";

let mongoDb =
  "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/mongodb-colored.svg";

let java =
  "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/java-colored.svg";

let javascr =
  "https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/javascript-colored.svg";
const data = [
  {
    id: 0,
    image: [medicalImage0, medicalImage1, medicalImage2],
    name: "Medical Appointment System",
    teck_stack: [
      { name: NodeJs, alt: "nodejs" },
      { name: react, alt: "reactjs" },
      { name: redux, alt: "Redux toolkit" },
      { name: mongoDb, alt: "MongoDB" },
      { name: express, alt: "express" },
      { name: javascr, alt: "javascript" },
    ],
    live_link: "https://medical-app-zsgh.onrender.com",
  },
  {
    id: 1,
    image: [gameImage0, gameImage1, gameImage2],
    name: "Video Game Database",
    teck_stack: [
      { name: spring, alt: "spring" },
      { name: java, alt: "Java" },
      { name: react, alt: "reactjs" },
      { name: redux, alt: "Redux toolkit" },
      { name: postgres, alt: "postgres" },
      { name: javascr, alt: "javascript" },
    ],
    live_link: "https://rawg-app.onrender.com",
  },
  {
    id: 2,
    image: [sneakersImg, sneakersImg1, sneakersImg2],
    name: "Sneakers Shop",
    live_link: "https://mini-shopping-cart.onrender.com",
    teck_stack: [
      { name: NodeJs, alt: "nodejs" },
      { name: react, alt: "reactjs" },
      { name: redux, alt: "Redux toolkit" },
      { name: mongoDb, alt: "MongoDB" },
      { name: express, alt: "express" },
      { name: javascr, alt: "javascript" },
    ],
  },
];

export default data;
